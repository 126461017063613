// Disable caching of AJAX responses
$.ajaxSetup({
    cache: false
});

//autocomplete search
var currentFocus = -1;
$(document).on('input', ".SearchBox", function(event) {
    var thisSearchBox 	= $(this);
    var partial         = $(this).val();

    if ( partial.replace(/[^A-Z]/gi, "").length >= 2 ) {
        //console.log("/ajax/"+thisSearchBox.attr('ajax'));

        if(thisSearchBox.data('ajax')) {
            $.get("/ajax/"+thisSearchBox.data('ajax'),
                {
                    partial: partial,
                    id:      thisSearchBox.data('id')
                }, 
                function(data) {
                    thisSearchBox.siblings( ".AjaxSearchResult" ).html(data).show();
                    currentFocus = -1;
                }
            );
        }
        else {
            $.get("/ajax/"+thisSearchBox.attr('ajax'),
                {
                    partial: partial,
                    id:         thisSearchBox.data('id')
                }, 
                function(data) {
                    thisSearchBox.siblings( ".AjaxSearchResult" ).html(data).show();
                    currentFocus = -1;
                }
            );
            }		
    }
});


$(document).on('keydown', ".SearchBox", function(event) {
    var x = $(".PartialCell");

    if (event.keyCode == 40) {
        /*If the arrow DOWN key is pressed, increase the currentFocus variable:*/
        currentFocus++;
        /*and and make the current item more visible:*/
        selectActive( addActive(x) );
        
    } 
    else if (event.keyCode == 38) { //up
        event.preventDefault();
        /*If the arrow UP key is pressed, decrease the currentFocus variable:*/
        currentFocus--;
        /*and and make the current item more visible:*/
        selectActive( addActive(x) );
    } 
    else if (event.keyCode == 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        event.preventDefault();
        if (currentFocus > -1) {
            /* and simulate a click on the "active" item:*/
            if (x) x[currentFocus].click();
        }
    }
    else {
        resetId(this);
    }
});
$(document).on('click', ".PartialCell", function() {
    selectActive(this)

    var myNode = $(".AjaxSearchResult")

    for( var i in myNode ) {
        while (myNode[i].firstChild) {
            myNode[i].removeChild(myNode[i].firstChild);
        }
    }
});
function addActive(x) {
    /*a function to classify an item as "active":*/
    if (!x) return false;
    
    /*start by removing the "active" class on all items:*/
    removeActive(x);
    
    if (currentFocus >= x.length) currentFocus = 0;
    
    if (currentFocus < 0) currentFocus = (x.length - 1);
    
    /*add class "autocomplete-active":*/
    x[currentFocus].classList.add("autocomplete-active");

    return x[currentFocus]
}
function removeActive(x) {
    /*a function to remove the "active" class from all autocomplete items:*/
    for (var i = 0; i < x.length; i++) {
        x[i].classList.remove("autocomplete-active");
    }
}
function selectActive(x) {
    $(x).parents().siblings(".SearchBox").val( $(x).find(".p-cell-main").text().trim() );
    $(x).parents().siblings(".AjaxSearchID").val( $(x).find("[name='partial_id']").val() );
}
function resetId(x) {
    $(x).siblings(".AjaxSearchID").val( '' );
}

function closeAllAutocomplete(elmnt) {
    /*close all autocomplete lists in the document, except the one passed as an argument:*/
    var myNode = document.getElementsByClassName("AjaxSearchResult");
    for( var i in myNode ) {
        while (myNode[i].firstChild) {
            myNode[i].removeChild(myNode[i].firstChild);
        }
    }
}

/*execute a function when someone clicks in the document:*/
document.addEventListener("click", function (e) {
    closeAllAutocomplete(e.target);
});