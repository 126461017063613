// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("jquery")

// require("jquery-ui/ui/widgets/datepicker")
// require("jquery-ui/themes/base/core.css")
// require("jquery-ui/themes/base/menu.css")
// require("jquery-ui/themes/base/datepicker.css")
// require("jquery-ui/themes/base/theme.css")

//require("chartkick")
//require("chart.js")
import "chartkick/chart.js"

window.jQuery = $;
window.$ = $;

import "bootstrap"
import "../stylesheets/application"  

require("packs/ajax_searchbox")
require("packs/hovercard")
require("packs/flatpickr")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)



$(function() {

	// Delete modal body when close
	$('#modal-lg').on('hidden.bs.modal', function (e) {
		$("#modal-load-lg").html("");
	})


		
	// });
	// $( "#datepicker" ).datepicker({
	// 	dateFormat: "yy-mm-dd",
	// 	gotoCurrent: true,
	// 	changeMonth: true,
	// 	changeYear: true
	// });

	// $( ".datepicker" ).datepicker({
	// 	dateFormat: "yy-mm-dd",
	// 	gotoCurrent: true,
	// 	changeMonth: true,
	// 	changeYear: true
	// });

	//initialize_datepicker();
	// $(document).on( "change", "#from_date", function() {
	// 	to.datepicker( "option", "minDate", getDate( this ) );
	// });

	// $(document).on( "change", "#to_date", function() {
	// 	console.log("hello12312312")
	// 	from.datepicker( "option", "maxDate", getDate( this ) );
	// });

	// $(document).on( "change", "#edit_from-date", function() {
	// 	edit_to.datepicker( "option", "minDate", getDate( this ) );
	// });
	// $(document).on( "change", "#edit_to-date", function() {
	// 	edit_from.datepicker( "option", "maxDate", getDate( this ) );
	// });

	// $(document).on( "change", ".From-date", function() {
	// 	$(".From-date").datepicker( "option", "minDate", getDate( this ) );
	// });
	// $(document).on( "change", ".To-date", function() {
	// 	$(".To-date").datepicker( "option", "maxDate", getDate( this ) );
	// });
		
	
 
    
	// old js for popup ... remove in future
	// $(document).on('click', ".Popup_Trigger", function() {
		
	// 	$pload 	= $("#popup-load");
	// 	pfrom 	= $pload.attr("popup-from");
		
	// 	if( !$pload.is(':empty') ) {
	// 		$("[popup-id='"+pfrom+"'").append( $pload.children() );
	// 	}
		
	// 	ptarget = $(this).attr('popup-trigger');
	// 	$pload = $("[popup-id='"+ptarget+"']");
		
	// 	$("#popup-load").append( $pload.children() ).attr("popup-from", $pload.attr("popup-id") );
		
	// 	displayPopup();
	// $(document).on('click', ".Popup_Cancel", function() {
		
	// 	$pload 	= $(this).parent().siblings("#popup-load");
	// 	pfrom 	= $pload.attr("popup-from");
		
	// 	$("[popup-id='"+pfrom+"'").append( $pload.children() );
		
	// 	hidePopup();
		
	// });
	
	//$(document).on('click', "input[type='text']", function() {
	//	$(this).select();
	//});



	$(".clear-form").on( "click", function() {
		$( "input[type='text'].clear-this" ).val('');
		$( "input[type='date'].clear-this" ).val('');
		//$( "select" ).val($("select option:first").val());

		$( "select.clear-this" ).each(function( index ) {
			$(this)[0].selectedIndex = 0;
		  });
	});

	
});	


// function initialize_datepicker() {

// 	$( "#from_date" )
// 		.datepicker({
// 			dateFormat: "yy-mm-dd",
// 			changeMonth: true,
// 			changeYear: true
// 	});
// 	$( "#to_date" )
// 		.datepicker({
// 			dateFormat: "yy-mm-dd",
// 			changeMonth: true,
// 			changeYear: true
// 	});

// 	$( "#edit_from-date" )
// 		.datepicker({
// 			dateFormat: "yy-mm-dd",
// 			changeMonth: true,
// 			changeYear: true
// 	});
// 	$( "#edit_to-date" )
// 		.datepicker({
// 			dateFormat: "yy-mm-dd",
// 			changeMonth: true,
// 			changeYear: true
// 	});
// }

var dateFormat = "yy-mm-dd";
function getDate( element ) {
	var date;
	try {
		date = $.datepicker.parseDate( dateFormat, element.value );
	} catch( error ) {
		date = null;
	}
	return date;
}

// function displayPopup() {
// 	$(".Popup").show();
// 	$(".modal-background").show();
		
// 	var top = ( ( $(window).height()/2 )-( $(".Pupcontainer2").height()/2) );
// 	if (top < 0) 
// 		top = 0;

// 	$("body").css("overflow","hidden");
// 	$(".Pupcontainer2").css("left",( ( $(window).width()/2 )-( $(".Pupcontainer2").width()/2 ) ) );
// 	$(".Pupcontainer2").css("top", top );
	
// }

// function removePopup() {
// 	$(".Popup").hide();
// 	$("body").css("overflow","auto");
	
// }

// function hidePopup() {
// 	$(".Popup").hide();
// 	$("body").css("overflow","auto");
	
// }


function add_fields(link, association, content) {
	var new_id = new Date().getTime();
	var regexp = new RegExp("new_"+association, "g");
	$(link).parent().before(content.replace(regexp, new_id));

}

	
function copyPerm()
{
	if( document.getElementById("PermAddrCheck").checked == true)
	{
		document.getElementById("person_PermAddrLine1").value = document.getElementById("person_AddrLine1").value;
		document.getElementById("person_PermAddrLine2").value = document.getElementById("person_AddrLine2").value;
		document.getElementById("person_PermAddrCity").value = document.getElementById("person_AddrCity").value;
		document.getElementById("person_PermAddrPostalCode").value = document.getElementById("person_AddrPostalCode").value;
		
		for(i=0; i<document.getElementById("person_AddrStateID").options.length;i++)
		{
			if(document.getElementById("person_AddrStateID").options[i].selected)
				document.getElementById("person_PermAddrStateID").options[i].selected=true;
		}
		for(i=0; i<document.getElementById("person_AddrCountryID").options.length;i++)
		{
			if(document.getElementById("person_AddrCountryID").options[i].selected)
				document.getElementById("person_PermAddrCountryID").options[i].selected=true;
		}
	}
}
function copyBilling()
{
	if( document.getElementById("BillingAddrCheck").checked == true)
	{
		document.getElementById("person_BillingPersonName").value = document.getElementById("person_NameFirst").value + " " + document.getElementById("person_NameLast").value;
		document.getElementById("person_BillingPersonPhone").value = document.getElementById("person_CellPhone").value;
		document.getElementById("person_BillingPersonEmail").value = document.getElementById("person_EmailAddress").value;
		document.getElementById("person_BillingAddrLine1").value = document.getElementById("person_AddrLine1").value;
		document.getElementById("person_BillingAddrLine2").value = document.getElementById("person_AddrLine2").value;
		document.getElementById("person_BillingAddrCity").value = document.getElementById("person_AddrCity").value;
		document.getElementById("person_BillingAddrPostalCode").value = document.getElementById("person_AddrPostalCode").value;
		
		for(i=0; i<document.getElementById("person_AddrStateID").options.length;i++)
		{
			if(document.getElementById("person_AddrStateID").options[i].selected)
				document.getElementById("person_BillingAddrStateID").options[i].selected=true;
		}
		for(i=0; i<document.getElementById("person_AddrCountryID").options.length;i++)
		{
			if(document.getElementById("person_AddrCountryID").options[i].selected)
				document.getElementById("person_BillingAddrCountryID").options[i].selected=true;
		}
	}
}






$(function() {
	SponsorCheck();

	$( document ).ajaxComplete(function() {
		SponsorCheck()
	});
	$(document).on('change', "[name='SponsorPulldown'], [name='application_funding']", function() {
		SponsorCheck();
	});
	
});
function SponsorCheck() {
	if($("[name='SponsorPulldown'], [name='application_funding']").val() != 'nl') {
		$("#grant_Sponsor, [name='application_funding_text']").attr("disabled","disabled").css("background","#ddd").val('');
	}
	else {
		$("#grant_Sponsor, [name='application_funding_text']").removeAttr("disabled").css("background","");
	}
}	



//Discipline
$(document).on( "change", '[name="DiscCat"]', function() {
	if ( $('[name="DiscCat"]').val() != 'Other') {
		$.get("/ajax/disciplines_ajax",
		{
			discipline_category: $('[name="DiscCat"]').val()
		
		},
		function(data, status) {
			$( "#discSearchResult" ).remove();
			$( "#discSearchWrap" ).append(data);
			
		});
	}
	else {
		$( "#discSearchResult" ).remove();
		$( "#discSearchWrap" ).append("<div id='discSearchResult'><span class='semi-sm'>Type Other Discipline Here:</span><input class='form-control  form-control-sm' type='text' name='application[DisciplineOther]' value='' required /></div>");
	}
		
});




//Method substabce
$(function() { 
	method_chem_req()

	$( document ).ajaxComplete(function() {
		method_chem_req()
	});
	$( document ).on( "change", "input[name='application[MethodChemicals]']", function() {
		method_chem_req()
	});
});
function method_chem_req() {
	if( $("#application_MethodChemicals_true:checked").length > 0 ){
		$("#application_MethodChemicalsList").prop('required',true)
	}
	else {
		$("#application_MethodChemicalsList").prop('required',false)
	}
}


//Permit requirement check
$(function() { 
	$('input.permit-answer-yes').each( function(){
		permit_req(this)
	})

	$( document ).ajaxComplete(function() {
		$('input.permit-answer-yes').each( function(){
			permit_req(this)
		})
	});

	$( document ).on( "change", 'input.permit-answer', function() {
		permit_req(this)
	})
	
	//$('input').filter(function() { return this.name.match(/application\[permit_answers_attributes\]\[[0-9]+\]\[PermitAnswer\]/); }).on('change', function(){
	//	permit_req()
	//});
});
function permit_req(x) {
	data_id = $(x).data('id')

	if ( $('input.permit-answer-yes:checked').filter(`[data-id='${data_id}']`).length > 0 ){
		$('input.permit-answer-name').filter(`[data-id='${data_id}']`).prop('required',true)
		$('input.permit-answer-date').filter(`[data-id='${data_id}']`).prop('required',true)
	}
	else {
		$('input.permit-answer-name').filter(`[data-id='${data_id}']`).prop('required',false)
		$('input.permit-answer-date').filter(`[data-id='${data_id}']`).prop('required',false)
	}
}






$(function() {
	vert_hide_show()

	$( document ).ajaxComplete(function() {
		vert_hide_show()
	});

	$( document ).on( "click", ".vert-yes", function() {
		$(".VertebrateList input[type=radio]").prop('checked',false);
		$(".VertebrateList").collapse('show');
	});
	
	$( document ).on( "click", ".vert-no", function() {
		if( $(".VertebrateList:hidden").length > 0 ) {
			$(".VertebrateList input[value=0]").prop('checked',true);
		}
		else {
			$(".VertebrateList").collapse('hide')
			$('.VertebrateList').on('hidden.bs.collapse', function () {
				$(".VertebrateList input[value=0]").prop('checked',true);
			})
		}
	});	

});
function vert_hide_show() {
	//show/hide permit vertebrate questions
	if ( $(".vert-yes").is(":checked")) {
		$(".VertebrateList").addClass('show')		
	}
}



//script to check/uncheck app member permission
$(document).on("change",".lead-cbox",function() {
	if (this.checked) {
		$("input.no-lead-cbox").prop('checked', true);
	}
});
$(document).on("change",".no-lead-cbox",function() {
	if (!this.checked) {
		$("input.lead-cbox").prop('checked', false);
	}
});




// $(document).on('submit','form.needs-validation',function(event){
// 	if (this.checkValidity() === false) {
// 		event.preventDefault();
// 		event.stopPropagation();
// 		this.classList.add('was-validated');
// 		return false
// 	}
// 	else {
// 		this.classList.add('was-validated');
// 	}
// });

//bootstrap client side validation for ajax
var submitActor = null;
$(document).on('ajax:before', 'form.needs-validation', function(event) {
	if( submitActor != null ) {
		if (this.checkValidity() === false) {
			this.classList.add('was-validated');
			submitActor = null
			return false;
		}
		this.classList.add('was-validated');
	}
});
$(document).on('click', 'form.needs-validation input[type=submit]', function(event) {
	console.log("type submit was clicked")
	submitActor = this
	
});


//bootstrap client side validation non ajax
(function() {
	'use strict';
	window.addEventListener('load', function() {
  
	  // Fetch all the forms we want to apply custom Bootstrap validation styles to
	  var forms = document.getElementsByClassName('needs-validation');

	  // Loop over them and prevent submission
	  var validation = Array.prototype.filter.call(forms, function(form) {
		form.addEventListener('submit', function(event) {
		  if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		  }
		  form.classList.add('was-validated');
		}, false);
	  });
	}, false);
})();

//bootstrap client side validation when new element is loaded by ajax
$( document ).ajaxComplete(function(event) {
	// Fetch all the forms we want to apply custom Bootstrap validation styles to
	var forms = document.getElementsByClassName('needs-validation');

	// Loop over them and prevent submission
	var validation = Array.prototype.filter.call(forms, function(form) {
	  form.addEventListener('submit', function(event) {
		if (form.checkValidity() === false) {
		  event.preventDefault();
		  event.stopPropagation();
		}
		form.classList.add('was-validated');
	  }, false);
	});
});


// Alert/Flash autohide
$( document ).ajaxComplete(function() { closeAlert(); });
$(function(){ closeAlert() });

function closeAlert() {
	if ( document.getElementsByClassName("alert").length > 0 ){
		window.setTimeout(function() {
			$("#flash > .alert.autohide" ).alert('close');
			
		}, 7500);
	}
}



//for direct upload
addEventListener("direct-upload:initialize", event => {
	const { target, detail } = event
	const { id, file } = detail
	target.insertAdjacentHTML("beforebegin", `
	  <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
		<div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
		<span class="direct-upload__filename">${file.name}</span>
	  </div>
	`)
})

addEventListener("direct-upload:start", event => {
	const { id } = event.detail
	const element = document.getElementById(`direct-upload-${id}`)
	element.classList.remove("direct-upload--pending")
})

addEventListener("direct-upload:progress", event => {
	const { id, progress } = event.detail
	const progressElement = document.getElementById(`direct-upload-progress-${id}`)
	progressElement.style.width = `${progress}%`
})

addEventListener("direct-upload:error", event => {
	event.preventDefault()
	const { id, error } = event.detail
	const element = document.getElementById(`direct-upload-${id}`)
	element.classList.add("direct-upload--error")
	element.setAttribute("title", error)
})

addEventListener("direct-upload:end", event => {
	const { id } = event.detail
	const element = document.getElementById(`direct-upload-${id}`)
	element.classList.add("direct-upload--complete")
})

