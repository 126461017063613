//hovercard js

var popperInstance
var hover_timer
var timer_lag_to_hover
var this_ref
var popper
var popper_data
var ajax_data

$(function() {
	popper  		= $("#popper")
	popper_data  	= $("#popper #data")
});

$(document).on( "mouseenter", ".hovercard-js", function(){
	var url
	console.log("mouseenter")
    clearTimeout(hover_timer);
	
	if ( popperInstance == null || this_ref != this ) {
		this_ref    	= this

		if ( this_ref.dataset.hovercardReserve ) {
			if ( this_ref.dataset.hovercardType == "user" ) {
				url = `/manager/reserves/${this_ref.dataset.hovercardReserve}/users/${this_ref.dataset.hovercardId}/hovercard`
			}
			else if ( this_ref.dataset.hovercardType == "app" ) {
				url = `/manager/reserves/${this_ref.dataset.hovercardReserve}/applications/${this_ref.dataset.hovercardId}/hovercard`	
			}
			else if ( this_ref.dataset.hovercardType == "act" ) {
				url = `/manager/reserves/${this_ref.dataset.hovercardReserve}/reservations/${this_ref.dataset.hovercardId}/hovercard`	
			}
		}
		else {
			if ( this_ref.dataset.hovercardType == "user" ) {
				url = "/manager/users/"+this_ref.dataset.hovercardId+"/hovercard"
			}
			else if ( this_ref.dataset.hovercardType == "app" ) {
				url = "/manager/applications/"+this_ref.dataset.hovercardId+"/hovercard"	
			}
			else if ( this_ref.dataset.hovercardType == "act" ) {
				url = "/manager/reservations/"+this_ref.dataset.hovercardId+"/hovercard"	
			}
		}
	

		$.get({
				url, 
				cache: true
			},
			function(data) {
				ajax_data = data
			}
		);

		timer_lag_to_hover = setTimeout(function(){ 
			//console.log(popper)
			//console.log(this_ref)

			create_popper(this_ref, popper, function() {
				popper_data.html(ajax_data)
				popper.show() 
			});
			
		}, 400);
	}
    
});
$(document).on("mouseenter","#popper", function(){
    clearTimeout(hover_timer);
});

$(document).on("mouseleave",".hovercard-js, #popper", function(){
    console.log("mouseout")
	
	if (popperInstance && !popperInstance.state.isDestroyed) {
    	hover_timer = setTimeout(remove_popper, 200);
	}
	else {
		this_ref = null
		clearTimeout(timer_lag_to_hover);
	}
});



function create_popper(ref, popper, callback){
    popperInstance = new Popper(ref, popper, {
		// popper options here
		placement: 'top',
		modifiers: {
			offset: {
				enabled: true,
				offset: "0px, 10px",
			},
			preventOverflow: {
				boundariesElement: 'window'
			}
		},
	});

	callback();
}

function remove_popper(){
    $("#popper").hide(); 
	popperInstance.destroy()
	this_ref = null
	console.log("remove")
}
